// Load Styles
import '../../scss/landing-v2/main-landing.scss';
import '../../scss/landing-v2/fonts/GothamPro-Medium.woff';
import {initBootstrap} from "../bootstrap";

initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false
});

import $ from "jquery";

//------------------------------------
//         HEADER
//------------------------------------

const forIndividualBtnHeader = document.querySelector('#for-individuals')
const forIndividualBtnHeaderMobile = document.querySelector('#for-individuals-mobile')
const forBusinessBtnHeader = document.querySelector('#for-business')
const forBusinessBtnHeaderMobile = document.querySelector('#for-business-mobile')


if (forIndividualBtnHeader && forIndividualBtnHeaderMobile && forBusinessBtnHeader && forBusinessBtnHeaderMobile) {

  forIndividualBtnHeader.addEventListener('click', scrollToForIndividual)
  forIndividualBtnHeaderMobile.addEventListener('click', scrollToForIndividual)
  forBusinessBtnHeader.addEventListener('click', scrollToForBusiness)
  forBusinessBtnHeaderMobile.addEventListener('click', scrollToForBusiness)

}




function scrollToForIndividual() {
  forPeopleBtn.classList.add('active')
  forBusinessBtn.classList.remove('active')
  forPeopleContainer.classList.remove('d-none')
  forPeopleContainer.classList.add('d-flex')
  forBusinessContainer.classList.add('d-none')

  document.getElementById('why-vidi').scrollIntoView()
}

function scrollToForBusiness() {
  forBusinessBtn.classList.add('active')
  forPeopleBtn.classList.remove('active')
  forBusinessContainer.classList.remove('d-none')
  forBusinessContainer.classList.add('d-flex')
  forPeopleContainer.classList.add('d-none')

  document.getElementById('why-vidi').scrollIntoView()
}

//------------------------------------
//          What ViDi
//------------------------------------

const forBusinessBtn = document.querySelector('#business');
const forPeopleBtn = document.querySelector('#people');

const forBusinessContainer = document.querySelector('.for-business');
const forPeopleContainer = document.querySelector('.for-people');


if (forBusinessBtn) {
  forBusinessBtn.addEventListener('click', function () {
    forBusinessBtn.classList.add('active')
    forPeopleBtn.classList.remove('active')
    forBusinessContainer.classList.remove('d-none')
    forBusinessContainer.classList.add('d-flex')
    forPeopleContainer.classList.add('d-none')
  })
}

if (forPeopleBtn) {
  forPeopleBtn.addEventListener('click', function () {
    forPeopleBtn.classList.add('active')
    forBusinessBtn.classList.remove('active')
    forPeopleContainer.classList.remove('d-none')
    forPeopleContainer.classList.add('d-flex')
    forBusinessContainer.classList.add('d-none')
  })
}


//------------------------------------
//          TARIFF
//------------------------------------

const btnTrainee = document.querySelector('#btn-trainee')
const btnMain = document.querySelector('#btn-main')
const btnPro = document.querySelector('#btn-pro')
const btnFinansist = document.querySelector('#btn-finansist')
const btnInvestor = document.querySelector('#btn-investor')

const cardTrainee = document.querySelector('#trainee')
const cardMain = document.querySelector('#main')
const cardPro = document.querySelector('#pro')
const cardFinansist = document.querySelector('#finansist')
const cardInvestor = document.querySelector('#investor')


if (btnTrainee && btnMain && btnPro && btnFinansist && btnInvestor) {

  btnTrainee.addEventListener('click', function () {
    btnTrainee.classList.add('active');
    btnMain.classList.remove('active');
    btnPro.classList.remove('active');
    btnFinansist.classList.remove('active');
    btnInvestor.classList.remove('active');

    cardTrainee.classList.remove('d-none');
    cardMain.classList.add('d-none');
    cardPro.classList.add('d-none');
    cardFinansist.classList.add('d-none');
    cardInvestor.classList.add('d-none');
  })

  btnMain.addEventListener('click', function () {
    btnTrainee.classList.remove('active');
    btnMain.classList.add('active');
    btnPro.classList.remove('active');
    btnFinansist.classList.remove('active');
    btnInvestor.classList.remove('active');

    cardTrainee.classList.add('d-none');
    cardMain.classList.remove('d-none');
    cardPro.classList.add('d-none');
    cardFinansist.classList.add('d-none');
    cardInvestor.classList.add('d-none');
  })

  btnPro.addEventListener('click', function () {
    btnTrainee.classList.remove('active');
    btnMain.classList.remove('active');
    btnPro.classList.add('active');
    btnFinansist.classList.remove('active');
    btnInvestor.classList.remove('active');

    cardTrainee.classList.add('d-none');
    cardMain.classList.add('d-none');
    cardPro.classList.remove('d-none');
    cardFinansist.classList.add('d-none');
    cardInvestor.classList.add('d-none');
  })

  btnFinansist.addEventListener('click', function () {
    btnTrainee.classList.remove('active');
    btnMain.classList.remove('active');
    btnPro.classList.remove('active');
    btnFinansist.classList.add('active');
    btnInvestor.classList.remove('active');

    cardTrainee.classList.add('d-none');
    cardMain.classList.add('d-none');
    cardPro.classList.add('d-none');
    cardFinansist.classList.remove('d-none');
    cardInvestor.classList.add('d-none');
  })

  btnInvestor.addEventListener('click', function () {
    btnTrainee.classList.remove('active');
    btnMain.classList.remove('active');
    btnPro.classList.remove('active');
    btnFinansist.classList.remove('active');
    btnInvestor.classList.add('active');

    cardTrainee.classList.add('d-none');
    cardMain.classList.add('d-none');
    cardPro.classList.add('d-none');
    cardFinansist.classList.add('d-none');
    cardInvestor.classList.remove('d-none');
  })

}


//------------------------------------
//          Road-Map
//------------------------------------

const btnLeft = document.querySelector('.left-arrow')
const btnRight = document.querySelector('.right-arrow')

const btnLeftDisable = document.querySelector('.left-arrow-disable')
const btnRightDisable = document.querySelector('.right-arrow-disable')

const scrollContainer = document.querySelector('.scroll-wrapper')

if (btnRight && btnLeft) {

  btnRight.onclick = function () {
    scrollContainer.scrollLeft += 150;
  };


  btnLeft.onclick = function () {
    scrollContainer.scrollLeft -= 150;
  };

}




if (scrollContainer) {

  scrollContainer.addEventListener('scroll', function (e) {
    buttonDisabler(e.target.scrollLeft)
  })

}

function buttonDisabler(scrollPosition) {
  let maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;

  if (scrollPosition === 0) {
    btnLeft.classList.add('d-none')
    btnLeftDisable.classList.remove('d-none')
  } else if (scrollPosition === maxScroll || scrollPosition > maxScroll) {
    btnRight.classList.add('d-none')
    btnRightDisable.classList.remove('d-none')
  } else {
    btnLeftDisable.classList.add('d-none')
    btnRightDisable.classList.add('d-none')
    btnLeft.classList.remove('d-none')
    btnRight.classList.remove('d-none')
  }

}
